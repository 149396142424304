<template>
    <div class="fault_his">
        <div>历史修故障单</div>
        <van-search v-model="confirmKeys" shape="round" background="#f7f8fa" placeholder="请输入搜索关键词" />
        <!-- 故障单列表 -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <van-cell-group v-for="item in list" :key="item.id" inset>
                <van-cell>
                    <template #title>
                        <div class="maintenance_list">
                            <div class="confirm_title">{{ item.info + '---维修人：' + item.maintenancePersonnel }}</div>
                            <!-- <div class="confirm_button">维修</div> -->
                        </div>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import Vue from 'vue'

import { Grid, GridItem, Search, List, Cell, CellGroup } from 'vant';

Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Search);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);

export default {
    data() {
        return {
            confirmKeys: '',
            list: [],
            loading: false,
            finished: false,
        }
    },
    methods: {
        onLoad() {
            // 异步更新数据
            // setTimeout 仅做示例，真实场景中一般为 ajax 请求
            // setTimeout(() => {
            //     for (let i = 0; i < 5; i++) {
            //         this.list.push({
            //             info: 'A区1线-组框机1号-ABCABCAB 2023/10/23:10:42已持续20分钟组件吸附异常/破真空异常',
            //             id: this.list.length
            //         });
            //     }

            //     // 加载状态结束
            //     this.loading = false;

            //     // 数据全部加载完成
            //     if (this.list.length >= 10) {
            //         this.finished = true;
            //     }
            // }, 1000);
            this.list = JSON.parse(sessionStorage.getItem('maintenanceData')).filter(item => item.isRepair == true)
            this.finished = true
        },
    },
    mounted() {
        // let id = this.$route.query.id
        // this.maintenanceItem = JSON.parse(sessionStorage.getItem('maintenanceData')).find(item => item.id == id)
    }

}
</script>

<style lang="scss" scoped>
.fault_his {
    background-color: #f7f8fa;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 1.25rem;

    .van-search__content {
        background-color: #fff;
    }

    .van-cell-group--inset {
        margin: .625rem 1rem;
    }

    .van-cell {
        line-height: unset;
        padding: .9375rem;
    }

    .maintenance_list {
        display: flex;

        .confirm_title {
            flex: 1;
            word-break: break-all;
            text-align: left;
        }

        .confirm_button {
            margin-left: 1.25rem;
            width: 3.75rem;
            height: 3.75rem;
            background-color: #f7f8fa;
            border-radius: 50%;
            line-height: 3.75rem;
        }
    }
}
</style>